<template>
  <div>
    <v-row>
      <v-col>
        <p class="text-h5">{{ type | capitalize_first_letter }} Inquiries</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mb-4"
          label="Search"
          single-line
          hide-details
        />

        <v-data-table
          :headers="headers"
          :items="inquiries"
          :items-per-page="-1"
          :loading="loading"
          :loading-text="
            loading ? 'Please be patient this may take a while. . .' : ''
          "
          :search="search"
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-center justify-end">
              <v-btn
                v-if="type !== 'archive'"
                title="Make a Deal"
                @click="open_make_deal_dialog(item)"
                icon
              >
                <v-icon color="teal"> mdi-folder-plus-outline </v-icon>
              </v-btn>

              <v-btn
                class="ml-2"
                :title="type === 'archive' ? 'Unarchive' : 'Archive'"
                @click="handle_archive(item)"
                icon
              >
                <v-icon color="red">
                  {{
                    type === "archive" ? "mdi-archive-arrow-up" : "mdi-archive"
                  }}
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="make_a_deal_dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="orange darken-3" flat>
          <v-toolbar-title>
            <v-icon>mdi-run-fast</v-icon> Convert Inquiry to Deal
          </v-toolbar-title>

          <v-spacer />
          <v-btn icon title="Close" @click="close_make_a_deal_dialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-10">
          <v-row justify="center">
            <v-col xs="12" xl="8">
              <make-deal
                v-if="make_a_deal_dialog"
                :inquiry_data="temp_inquiry_data"
                @close="close_make_a_deal_dialog"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import MakeDeal from "@/components/inquiry/Make_Deal";

export default {
  props: ["type"],

  components: {
    MakeDeal,
  },

  data: () => ({
    loading: true,
    search: "",
    make_a_deal_dialog: false,
    temp_inquiry_data: null,

    inquiries: [],

    headers: [
      { text: "Name", value: "full_name" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Comments", value: "comments" },
      { text: "Created At", value: "createdAt" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  methods: {
    open_make_deal_dialog(item) {
      this.temp_inquiry_data = item;
      this.make_a_deal_dialog = true;
    },

    close_make_a_deal_dialog() {
      this.make_a_deal_dialog = false;
      this.temp_inquiry_data = null;
    },

    async handle_archive(inquiry) {
      try {
        const response = await API().post(
          "api/internal-admin/loan-inquiry/archived-inquiry",
          {
            id: inquiry.id,
            type: this.type == "archive" ? inquiry.type : this.type,
            archive: inquiry.archived,
          }
        );

        console.log(response.data.message);

        this.inquiries = this.inquiries.filter(
          (i) => i.id !== response.data.inquiry.id
        );
      } catch (error) {
        console.log(error);
        this.$emit("error", error);
      }
    },

    async getData() {
      if (this.type === "loan") {
        try {
          const response = await API().get(
            "api/internal-admin/loan-inquiry/read-all-loan-inquiries"
          );

          console.log(response.data.message);

          this.inquiries = response.data.loan_inquiries.map((inquiry) => {
            return {
              ...inquiry,
              createdAt: this.$options.filters.formatDate(inquiry.createdAt),
            };
          });

          this.loading = false;
        } catch (error) {
          console.log(error);
          this.$emit("error", error);
          this.loading = false;
        }
      }

      if (this.type === "archive") {
        try {
          const response = await API().get(
            "api/internal-admin/loan-inquiry/read-all-archived-inquiries"
          );

          console.log(response.data.message);

          this.inquiries = response.data.loan_inquiries.map((inquiry) => {
            return {
              ...inquiry,
              createdAt: this.$options.filters.formatDate(inquiry.createdAt),
            };
          });

          this.loading = false;
        } catch (error) {
          console.log(error);
          this.$emit("error", error);
          this.loading = false;
        }
      }
    },
  },

  mounted() {
    this.getData();
  },

  filters: {
    ...filt,
  },
};
</script>
