<template>
  <div>
    <!-- notification area -->
    <div class="sticky-row">
      <v-alert v-show="error" transition="fade-transition" color="error" dense>
        <v-row align="center">
          <v-col class="grow d-flex align-center">
            <div class="mr-5">
              <v-icon>mdi-alert-outline</v-icon>
            </div>

            <div>
              {{ errorMessage }}
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn title="close" icon @click="error = !error">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <v-stepper v-model="e1" flat>
      <v-stepper-header>
        <v-stepper-step color="teal" :complete="customer_valid" step="1">
          Verify Customer
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step color="teal" :complete="deal_valid" step="2">
          Create Deal
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step color="teal" step="3" editable>
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Verify Customer -->
        <v-stepper-content class="pa-0" step="1">
          <!-- if customer email address already exist -->
          <!-- choose if use existing or create new -->
          <div v-if="found_customers.length > 0" class="text-body-1 ma-5">
            <p>
              We found {{ found_customers.length }} customers with the same
              email address.
            </p>

            <p>
              Please make a choice:
              <v-btn-toggle v-model="toggle_choice" class="ml-5" rounded dense>
                <v-btn> Use Existing </v-btn>
                <v-btn> Create New </v-btn>
              </v-btn-toggle>
            </p>
          </div>

          <!-- if customer email address does not exist -->
          <!-- create new -->
          <div v-if="found_customers.length === 0" class="text-body-1 ma-5">
            <p>Please fill in the form below to create a new customer.</p>
          </div>

          <!-- saved customer preview -->
          <div
            v-if="customer_selected || customer_saved"
            class="mx-5 mb-5 mt-10"
          >
            <div class="d-flex flex-wrap align-center">
              <span class="text-body-1">Saved Customer Information</span>
              <v-btn title="Edit" @click="handle_edit_customer_icon" icon>
                <v-icon class="text-h5"> mdi-account-edit-outline </v-icon>
              </v-btn>
            </div>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="200">Full Name</td>
                    <td>
                      {{
                        customer_data
                          ? `${customer_data.salutation}. ${customer_data.first_name} ${customer_data.last_name}`
                          : ""
                      }}
                    </td>
                  </tr>

                  <tr
                    v-for="(contact_info, index) in customer_data.contact_infos"
                    :key="index + 'contact_info'"
                  >
                    <td>{{ contact_info.type }}</td>
                    <td>
                      {{
                        contact_info.email
                          ? contact_info.email
                          : contact_info.number
                      }}
                    </td>
                  </tr>

                  <tr
                    v-for="(address, index) in customer_data.address_data"
                    :key="index + 'address'"
                  >
                    <td>Present Address</td>
                    <td>
                      {{
                        address.street_number
                          ? $options.filters.capitalize(
                              String(address.street_number)
                            ) + ", "
                          : ""
                      }}
                      {{
                        address.street_name
                          ? $options.filters.capitalize_each_word(
                              String(address.street_name)
                            ) + ", "
                          : ""
                      }}
                      {{
                        address.street_type ? address.street_type + ", " : ""
                      }}
                      {{
                        address.street_direction
                          ? address.street_direction + ", "
                          : ""
                      }}
                      {{
                        address.unit
                          ? $options.filters.capitalize_each_word(
                              String(address.unit)
                            ) + ", "
                          : ""
                      }}
                      {{
                        address.postal_code
                          ? $options.filters.capitalize(
                              String(address.postal_code)
                            ) + ", "
                          : ""
                      }}
                      {{
                        address.city
                          ? $options.filters.capitalize_each_word(
                              String(address.city)
                            ) + ", "
                          : ""
                      }}
                      {{
                        address.province
                          ? $options.filters.capitalize_each_word(
                              String(address.province)
                            )
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- select existing customer -->
          <div
            v-if="
              toggle_choice === 0 &&
              found_customers.length > 0 &&
              !customer_selected
            "
            class="mx-5 mb-5 mt-10"
          >
            <p class="mb-2 text-body-1">Kindly select which customer</p>

            <v-data-table
              :headers="customer_header"
              :items="filtered_found_customers"
              :loading="customer_loading"
              loading-text="Please wait..."
              no-data-text="No customers found."
              no-results-text="No customers found."
              hide-default-footer
              :items-per-page="-1"
              item-key="id"
              @click:row="handle_select_customer"
              dense
            >
              <template v-slot:item.deal_count="{ item }">
                <div class="d-flex align-center justify-center">
                  {{ item.deal_count }}
                </div>
              </template>
            </v-data-table>
          </div>

          <!-- create new customer form -->
          <v-form ref="customer_form">
            <v-card
              v-if="
                (toggle_choice === 1 || found_customers.length === 0) &&
                !customer_saved
              "
              class="mx-5 mb-5 steppers-card rounded-lg"
              flat
            >
              <!-- basic information -->
              <v-card-text>
                <p class="text-subtitle-1 mb-0">Basic Information:</p>

                <v-select
                  v-model="new_customer_data.salutation"
                  label="Salutation"
                  :items="['Mr', 'Mrs', 'Dr', 'Rev', 'Miss', 'Judge']"
                  :rules="[rule.required]"
                />

                <v-text-field
                  v-model="new_customer_data.first_name"
                  label="First Name"
                  :rules="[rule.required]"
                />

                <v-text-field
                  v-model="new_customer_data.last_name"
                  label="Last Name"
                  :rules="[rule.required]"
                />
              </v-card-text>

              <v-divider></v-divider>

              <!-- contact information -->
              <v-card-text>
                <p class="text-subtitle-1 mb-0">Contacts :</p>

                <div
                  v-for="(
                    contact_info, index
                  ) in new_customer_data.contact_infos"
                  :key="index"
                >
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="contact_info.type"
                        label="Contact Type"
                        :items="[
                          'Email',
                          'Cell Phone Number',
                          'Home Phone Number',
                          'Work Phone Number',
                        ]"
                        :rules="[rule.required]"
                        @change="
                          contact_info.email = null;
                          contact_info.number = null;
                        "
                      />
                    </v-col>

                    <v-col>
                      <span v-if="contact_info.type === 'Email'">
                        <v-text-field
                          v-model="contact_info.email"
                          label="Email Address"
                          :rules="[...rule.email]"
                        />
                      </span>

                      <span v-if="contact_info.type !== 'Email'">
                        <v-text-field
                          v-model="contact_info.number"
                          label="Number"
                          :rules="[...rule.contact_number]"
                        />
                      </span>
                    </v-col>

                    <v-col cols="1" class="d-flex align-center justify-end">
                      <v-btn
                        title="Delete"
                        @click="
                          new_customer_data.contact_infos.splice(index, 1)
                        "
                        icon
                      >
                        <v-icon color="red"> mdi-close </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <v-btn
                  class="mt-2"
                  color="teal"
                  @click="
                    new_customer_data.contact_infos.push({
                      type: null,
                      email: null,
                      number: null,
                    })
                  "
                  small
                  text
                >
                  + Add Contact
                </v-btn>
              </v-card-text>

              <v-divider></v-divider>

              <!-- address information -->
              <v-card-text>
                <p class="text-subtitle-1 mb-0">Present Address :</p>

                <v-data-table
                  v-if="!adding_present_address && !updating_present_address"
                  :headers="[
                    { text: 'Full Address', value: 'address' },
                    { text: '', value: 'actions', sortable: false },
                  ]"
                  :items="new_customer_data.address_data"
                  :item-per-page="-1"
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.address="{ item, index }">
                    {{
                      item.street_number
                        ? $options.filters.capitalize(
                            String(item.street_number)
                          ) + ", "
                        : ""
                    }}
                    {{
                      item.street_name
                        ? $options.filters.capitalize_each_word(
                            String(item.street_name)
                          ) + ", "
                        : ""
                    }}
                    {{ item.street_type ? item.street_type + ", " : "" }}
                    {{
                      item.street_direction ? item.street_direction + ", " : ""
                    }}
                    {{
                      item.unit
                        ? $options.filters.capitalize_each_word(
                            String(item.unit)
                          ) + ", "
                        : ""
                    }}
                    {{
                      item.postal_code
                        ? $options.filters.capitalize(
                            String(item.postal_code)
                          ) + ", "
                        : ""
                    }}
                    {{
                      item.city
                        ? $options.filters.capitalize_each_word(
                            String(item.city)
                          ) + ", "
                        : ""
                    }}
                    {{
                      item.province
                        ? $options.filters.capitalize_each_word(
                            String(item.province)
                          )
                        : ""
                    }}
                  </template>

                  <template v-slot:item.actions="{ item, index }">
                    <div class="d-flex align-center justify-end">
                      <v-btn
                        icon
                        title="Edit"
                        @click="handle_edit_address(item, index)"
                      >
                        <v-icon color="teal"> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>

                <v-btn
                  v-if="
                    new_customer_data.address_data.length === 0 &&
                    !adding_present_address &&
                    !updating_present_address
                  "
                  class="mt-2"
                  color="teal"
                  @click="adding_present_address = true"
                  small
                  text
                >
                  + Add Present Address
                </v-btn>

                <!-- address form -->
                <v-card
                  v-if="adding_present_address || updating_present_address"
                >
                  <v-toolbar flat dense>
                    <v-toolbar-title>
                      {{ adding_present_address ? "Add" : "Update" }}
                      Present Address
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      title="Close"
                      @click="
                        adding_present_address = false;
                        updating_present_address = false;
                      "
                    >
                      <v-icon color="red"> mdi-close </v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-card-text class="mt-5">
                    <v-form ref="address_form">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="2">
                            <v-text-field
                              v-model="new_present_address.street_number"
                              label="Street Number"
                              hide-details="auto"
                              outlined
                              dense
                            />
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              v-model="new_present_address.street_name"
                              label="Street Name"
                              hide-details="auto"
                              outlined
                              dense
                            />
                          </v-col>

                          <v-col cols="2">
                            <v-select
                              v-model="new_present_address.street_type"
                              label="Street Type"
                              :items="street_type_options"
                              hide-details="auto"
                              dense
                            />
                          </v-col>

                          <v-col cols="2">
                            <v-select
                              v-model="new_present_address.street_direction"
                              label="Street Direction"
                              :items="street_direction_options"
                              hide-details="auto"
                              dense
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="2">
                            <v-text-field
                              v-model="new_present_address.unit"
                              label="Unit"
                              hide-details="auto"
                              outlined
                              dense
                            />
                          </v-col>

                          <v-col cols="2">
                            <v-text-field
                              v-model="new_present_address.postal_code"
                              label="Postal Code"
                              hide-details="auto"
                              :rules="[rule.required]"
                              outlined
                              dense
                            />
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              v-model="new_present_address.city"
                              label="City"
                              hide-details="auto"
                              :rules="[rule.required]"
                              outlined
                              dense
                            />
                          </v-col>

                          <v-col cols="4">
                            <v-select
                              v-model="new_present_address.province"
                              label="Province"
                              :items="province_options"
                              :rules="[rule.required]"
                              hide-details="auto"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="teal"
                      @click="
                        adding_present_address
                          ? handle_add_address()
                          : handle_save_updated_address()
                      "
                    >
                      {{ adding_present_address ? "+ Add" : "Save" }} Address
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions v-if="!customer_saved">
                <v-btn
                  :disabled="adding_present_address || updating_present_address"
                  color="teal"
                  title="Click to save customer"
                  @click="handle_save_customer"
                >
                  Save Customer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>

          <v-divider />

          <!-- actions -->
          <div class="ma-5">
            <v-btn :disabled="!customer_valid" color="teal" @click="e1 = 2">
              Continue
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Create Deal -->
        <v-stepper-content class="pa-0" step="2">
          <!-- saved deal preview -->
          <div v-if="deal_valid" class="mx-5 mb-5 mt-10">
            <div class="d-flex flex-wrap align-center">
              <span class="text-body-1">Saved Deal Information</span>
              <v-btn icon color="teal" @click="deal_valid = false">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="200">Internal Name</td>
                    <td>
                      {{ deal_data.internal_name | capitalize_each_word }}
                    </td>
                  </tr>

                  <tr>
                    <td>Internal Description</td>
                    <td>{{ deal_data.description }}</td>
                  </tr>

                  <tr>
                    <td>Province</td>
                    <td>{{ deal_data.province | province }}</td>
                  </tr>

                  <tr>
                    <td>Deal Purpose</td>
                    <td>{{ deal_data.deal_purpose | capitalize_each_word }}</td>
                  </tr>

                  <tr>
                    <td>Deal Type</td>
                    <td>{{ deal_data.deal_type | capitalize_each_word }}</td>
                  </tr>

                  <tr>
                    <td>Deal Stage</td>
                    <td>
                      {{
                        deal_stage_options.find(
                          (el) => el.value === deal_data.deal_stage
                        ).text | capitalize_each_word
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- create new deal form -->
          <v-card v-if="!deal_valid" class="ma-5 steppers-card rounded-lg" flat>
            <v-form ref="deal_form">
              <v-card-text>
                <v-row>
                  <v-col>
                    <p class="text-body-1 font-weight-bold">
                      <v-icon> mdi-folder-outline </v-icon>
                      Deal Information
                    </p>

                    <v-text-field
                      v-model="new_deal_data.internal_name"
                      label="Internal Name"
                      :rules="[rule.required]"
                    />

                    <v-textarea
                      v-model="new_deal_data.description"
                      auto-grow
                      outlined
                      label="Internal Description"
                      :rules="[rule.required]"
                    />

                    <v-select
                      v-model="new_deal_data.province"
                      label="Province"
                      :items="[
                        { text: 'Alberta', value: 'AB' },
                        { text: 'British Columbia', value: 'BC' },
                        { text: 'Ontario', value: 'ON' },
                      ]"
                      :rules="[rule.required]"
                    />

                    <v-select
                      v-model="new_deal_data.deal_purpose"
                      label="Deal Purpose"
                      :items="[
                        { text: 'Refinance', value: 'refinance' },
                        { text: 'Purchase', value: 'purchase' },
                        { text: 'Switch/Transfer', value: 'switch-transfer' },
                      ]"
                    />

                    <v-select
                      v-model="new_deal_data.deal_type"
                      label="Deal Type"
                      :items="[
                        { text: 'Approval', value: 'approval' },
                        { text: 'Pre-Approval', value: 'preapproval' },
                      ]"
                    />

                    <v-select
                      v-model="new_deal_data.deal_stage"
                      label="Deal Stage"
                      :items="deal_stage_options"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="teal"
                  title="Click to save deal"
                  @click="handle_save_deal"
                >
                  Save Deal Information
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

          <v-divider />
          <!-- actions -->
          <div class="ma-5">
            <v-btn :disabled="!deal_valid" color="teal" @click="e1 = 3">
              Continue
            </v-btn>
            <v-btn class="ml-5" @click="e1 = 1"> Back</v-btn>
          </div>
        </v-stepper-content>

        <!-- Confirmation -->
        <v-stepper-content class="pa-0" step="3">
          <v-card
            v-if="(!customer_valid || !deal_valid || !submitted) && !submitting"
            class="ma-5 rounded-lg"
          >
            <v-card-text class="text-h5">
              <p>
                {{
                  customer_valid && deal_valid
                    ? "We are ready to convert inquiry into deal"
                    : "We are still in progress . . ."
                }}
              </p>
              <p>
                <v-icon :color="!customer_valid ? 'red' : 'green'">
                  {{
                    !customer_valid ? "mdi-close" : "mdi-check-circle-outline"
                  }}
                </v-icon>
                Customer Information
              </p>

              <p>
                <v-icon :color="!deal_valid ? 'red' : 'green'">
                  {{ !deal_valid ? "mdi-close" : "mdi-check-circle-outline" }}
                </v-icon>
                Deal Information
              </p>
            </v-card-text>

            <v-card-text v-if="customer_valid && deal_valid">
              <v-btn color="teal" @click="handle_submit_deal">
                Create Deal
              </v-btn>
            </v-card-text>
          </v-card>

          <v-card
            v-if="submitting"
            class="ma-5 d-flex flex-column align-center justify-center steppers-card rounded-lg"
          >
            <p class="text-h5">
              <v-progress-circular indeterminate color="teal" size="64" />
              Please wait, this might take a while to create a new deal . . .
            </p>
          </v-card>

          <v-card
            v-if="submitted"
            class="ma-5 d-flex flex-column align-center justify-center steppers-card rounded-lg"
          >
            <p class="text-h4">Well done, Hooray!</p>
            <v-icon class="text-h1" color="green"> mdi-party-popper </v-icon>
            <v-btn
              class="mt-10"
              color="teal"
              @click="
                $router.push({
                  path: `/dashboard/admin/deal/read/${deal_temp_id}`,
                })
              "
            >
              view deal
            </v-btn>
          </v-card>

          <div v-if="!customer_valid || !deal_valid" class="ma-5">
            <v-btn @click="e1 = 2">Back</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import rule from "@/plugins/rule";

export default {
  props: ["inquiry_data"],

  data: () => ({
    customer_loading: false,
    submitting: false,
    error: false,
    errorMessage: "",
    submitted: false,

    customer_valid: false,
    deal_valid: false,

    customer_selected: false,
    customer_saved: false,

    adding_present_address: false,
    updating_present_address: false,

    e1: 1,

    rule: rule,

    customer_type: "new",

    new_customer_data: {
      id: null,
      salutation: null,
      first_name: null,
      last_name: null,

      contact_infos: [],

      address_data: [],

      poa_notes: [],
      credit_scores: [],
      relationship_data: [],
      identification_data: [],
      employment_data: [],
      additional_income_data: [],
      asset_data: [],
      liability_data: [],
    },

    new_present_address: {
      street_number: null,
      street_name: null,
      street_type: null,
      street_direction: null,
      unit: null,
      city: null,
      province: null,
      postal_code: null,
      present: true,
    },

    new_deal_data: {
      internal_name: null,
      description: null,
      province: null,
      deal_purpose: "refinance",
      deal_type: "approval",
      deal_stage: null,
    },

    customer_data: null,

    deal_data: null,

    deal_temp_id: null,

    deal_temp_note: null,

    toggle_choice: undefined,

    existing_contact_infos: [],
    found_customers: [],

    customer_header: [
      {
        text: "Customer Name",
        value: "customer_name",
      },

      {
        text: "Email",
        value: "email",
      },

      {
        text: "Cell Phone Number",
        value: "cell_phone_number",
        sortable: false,
      },

      {
        text: "Home Phone Number",
        value: "home_phone_number",
        sortable: false,
      },

      {
        text: "Work Phone Number",
        value: "work_phone_number",
        sortable: false,
      },

      {
        text: "Present Address",
        value: "address",
      },

      {
        text: "Deal Count",
        value: "deal_count",
        sortable: false,
      },
    ],

    // address options
    province_options: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Northwest Territories",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],

    street_direction_options: ["N", "S", "E", "W", "NE", "NW", "SE", "SW"],

    street_type_options: [
      "Abbey",
      "Acres",
      "Allée",
      "Alley",
      "Autoroute",
      "Avenue",
      "Bay",
      "Beach",
      "Bend",
      "Boulevard",
      "By-Pass",
      "Byway",
      "Campus",
      "Cape",
      "Carré",
      "Carrefour",
      "Centre",
      "Cercle",
      "Chase",
      "Chemin",
      "Circle",
      "Circuit",
      "Close",
      "Common",
      "Concession",
      "Corners",
      "Côte",
      "Cour",
      "Cours",
      "Court",
      "Cove",
      "Crescent",
      "Croissant",
      "Crossing",
      "Cul-de-sac",
      "Dale",
      "Dell",
      "Diversion",
      "Downs",
      "Drive",
      "Éhangeur",
      "End",
      "Esplande",
      "Estates",
      "Expressway",
      "Extension",
      "Farm",
      "Field",
      "Forest",
      "Freeway",
      "Front",
      "Gardens",
      "Gate",
      "Glen",
      "Green",
      "Grounds",
      "Grove",
      "Harbour",
      "Heath",
      "Heights",
      "Highlands",
      "Highway",
      "Hill",
      "Hollow",
      "Île",
      "Impasse",
      "Inlet",
      "Island",
      "Key",
      "knoll",
      "Landing",
      "Lane",
      "Limits",
      "Line",
      "Link",
      "Lookout",
      "Loop",
      "Mall",
      "Manor",
      "Maze",
      "Meadow",
      "Mews",
      "Montée",
      "Moor",
      "Mount",
      "Mountain",
      "Orchard",
      "Parade",
      "Parc",
      "Park",
      "Parkway",
      "Passage",
      "Path",
      "Pathway",
      "Pines",
      "Place",
      "Plateau",
      "Plaza",
      "Point",
      "Pointe",
      "Port",
      "Private",
      "Promenade",
      "Quai",
      "Quay",
      "Ramp",
      "Rang",
      "Range",
      "Ridge",
      "Rise",
      "Road",
      "Rond-point",
      "Route",
      "Row",
      "Rue",
      "Ruelle",
      "Run",
      "Sentier",
      "Square",
      "Street",
      "Subdivision",
      "Terrance",
      "Terrasse",
      "Thicket",
      "Towers",
      "Townline",
      "Trail",
      "Turnabout",
      "Vale",
      "Via",
      "View",
      "Village",
      "Villas",
      "Vista",
      "Voie",
      "Walk",
      "Way",
      "Wharf",
      "Wood",
      "Wynd",
    ],

    deal_stage_options: [],
  }),

  methods: {
    // customer methods
    handle_select_customer(item) {
      const customer = this.found_customers.find(
        (found_customer) => found_customer.id === item.id
      );

      customer.address_data = [...customer.addresses];

      this.customer_data = {
        id: customer.id,
        salutation: customer.salutation,
        first_name: customer.first_name,
        last_name: customer.last_name,
        contact_infos: customer.contact_infos,
        address_data: [
          ...customer.addresses.map((address) => {
            // Create a new object without the 'id' field
            const { id, ...addressWithoutId } = address;
            addressWithoutId.present = true;
            return addressWithoutId;
          }),
        ],

        poa_notes: [],
        credit_scores: [],
        relationship_data: [],
        identification_data: [],
        employment_data: [],
        additional_income_data: [],
        asset_data: [],
        liability_data: [],
      };

      this.customer_selected = true;
      this.customer_valid = true;
      this.customer_saved = false;
      this.customer_type = "existing";
    },

    handle_save_customer() {
      // validate customer form
      const valid = this.$refs.customer_form.validate();

      if (!valid) {
        this.$refs.customer_form.$el.scrollIntoView({ behavior: "smooth" });
        return;
      }

      this.customer_data = this.new_customer_data;
      this.customer_saved = true;
      this.customer_valid = true;
      this.customer_selected = false;
      this.customer_type = "new";
    },

    handle_edit_customer_icon() {
      if (this.customer_selected) {
        this.new_customer_data = this.customer_data;
        this.customer_selected = false;
        this.customer_saved = false;
        this.customer_valid = false;
      }

      if (this.customer_saved) {
        this.new_customer_data = this.customer_data;
        this.customer_saved = false;
        this.customer_selected = false;
        this.customer_valid = false;
      }
    },

    handle_add_address() {
      const valid = this.$refs.address_form.validate();

      if (!valid) {
        this.$refs.address_form.$el.scrollIntoView({ behavior: "smooth" });
        return;
      }

      this.new_customer_data.address_data.push(this.new_present_address);
      this.adding_present_address = false;
    },

    handle_edit_address(item) {
      this.new_present_address = item;
      this.updating_present_address = true;
    },

    handle_save_updated_address() {
      this.new_customer_data.address_data[0] = this.new_present_address;
      this.updating_present_address = false;
    },

    // deal methods
    handle_save_deal() {
      // validate deal form
      const valid = this.$refs.deal_form.validate();

      if (!valid) {
        this.$refs.deal_form.$el.scrollIntoView({ behavior: "smooth" });
        return;
      }

      this.deal_data = this.new_deal_data;
      this.deal_valid = true;
    },

    // other methods
    async handle_submit_deal() {
      try {
        this.submitting = true;

        console.log("sending to backend: ");
        console.log("customer data: ", this.customer_data);
        console.log("deal data: ", this.deal_data);
        let customer_response = null;

        // create new customer if customer id is null
        if (this.customer_data.id === null) {
          customer_response = await API().post(
            "api/internal-admin/loan-inquiry/create-customer",
            {
              customer: this.customer_data,
            }
          );

          console.log(customer_response.data.message);
          // console.log(customer_response.data.customer);
        }

        // use existing customer if customer id is not null
        if (this.customer_data.id !== null) {
          customer_response = this.customer_data;
        }

        // create new deal
        const deal_response = await API().post(
          "/api/internal-admin/createDeal",
          this.deal_data
        );

        // console.log("deal_response: ", deal_response);

        if (deal_response.status === 201) {
          // update kanban stage item
          const deal_stage_response = await API().post(
            "api/kanban/update_kanban_stage_item",
            {
              stage_id: this.deal_data.deal_stage,
              item_id: deal_response.data.id,
              type: "Deal",
              choice: "Archive and Create New",
            }
          );

          // console.log("deal_stage_response: ", deal_stage_response);

          // associate customer to deal
          const int_cust_response = await API().post(
            "api/internal-admin/customer/associate_customer_to_deal",
            {
              deal_id: deal_response.data.id,
              customer_id: this.customer_data.id
                ? customer_response.id
                : customer_response.data.customer.id,
            }
          );
          // console.log("int_cust_response: ", int_cust_response);

          // update loan inquiry status to archive and associate deal and customer
          const loan_inquiry_response = await API().patch(
            "api/internal-admin/loan-inquiry/associate-and-archive-inquiry",
            {
              inquiry_id: this.inquiry_data.id,
              deal_id: deal_response.data.id,
              customer_id: this.customer_data.id
                ? customer_response.id
                : customer_response.data.customer.id,
            }
          );

          // console.log("loan_inquiry_response: ", loan_inquiry_response);

          // add note to deal
          const deal_note_response = await API().post(
            "api/internal-admin/deal/add-note",
            {
              deal_id: deal_response.data.id,
              note: this.deal_temp_note,
              user_id: this.$store.getters["Auth/getAuthUser"].id,
            }
          );

          console.log("deal_note_response: ", deal_note_response.data.message);

          this.deal_temp_id = deal_response.data.id;
          this.submitting = false;
          this.submitted = true;
        } else {
          this.error = true;
          this.errorMessage = "Error: Deal not created";
          this.submitting = false;
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    initialize_new_customer_data() {
      this.new_customer_data.first_name = this.inquiry_data.first_name;
      this.new_customer_data.last_name = this.inquiry_data.last_name;

      if (this.inquiry_data.phone) {
        this.new_customer_data.contact_infos.push({
          type: "Cell Phone Number",
          email: null,
          number: this.inquiry_data.phone,
        });
      }

      if (this.inquiry_data.email) {
        this.new_customer_data.contact_infos.push({
          type: "Email",
          email: this.inquiry_data.email,
          number: null,
        });
      }

      if (!this.inquiry_data.phone && !this.inquiry_data.email) {
        this.new_customer_data.contact_infos.push({
          type: null,
          email: null,
          number: null,
        });
      }
    },

    initialize_new_deal_data() {
      try {
        this.new_deal_data.internal_name = `${this.$options.filters.capitalize_each_word(
          String(this.inquiry_data.first_name)
        )} ${this.$options.filters.capitalize_each_word(
          String(this.inquiry_data.last_name)
        )}`;

        let province = null;

        const inquiry_province = this.inquiry_data.province.toLowerCase();

        if (inquiry_province === "alberta") province = "AB";
        if (inquiry_province === "british columbia") province = "BC";
        if (inquiry_province === "ontario") province = "ON";

        this.new_deal_data.province = province;
      } catch (error) {
        console.log("Warning: Province was not defined in the loan_inquiry data.");
        return;
      }
    },

    initialize_deal_note() {
      const {
        agree_to_terms,
        amount_requested,
        comments,
        form_type,
        landing_page_name,
        landing_page_url,
        lending_experience,
        owing_against,
        property_type,
        property_value,
        street_address,
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
      } = this.inquiry_data;

      this.deal_temp_note = `This deal was created from a loan inquiry with other following details:
      Agree to Terms: ${agree_to_terms}
      Amount Requested: ${amount_requested}
      Comments: ${comments}
      Form Type: ${form_type}
      Landing Page Name: ${landing_page_name}
      Landing Page URL: ${landing_page_url}
      Lending Experience: ${lending_experience}
      Owing Against: ${owing_against}
      Property Type: ${property_type}
      Property Value: ${property_value}
      Street Address: ${street_address}
      UTM Campaign: ${utm_campaign}
      UTM Content: ${utm_content}
      UTM Medium: ${utm_medium}
      UTM Source: ${utm_source}`;
    },

    async get_existing_contact_info() {
      try {
        const response = await API().get(
          "api/internal-admin/customer/read_all_contact_infos"
        );

        this.existing_contact_infos = response.data;
      } catch (error) {
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    async get_existing_kanban_stages() {
      try {
        const response = await API().get(
          "api/internal-admin/loan-inquiry/read-kanban-stages"
        );

        for (const kbn_stg of response.data.kbn_stgs) {
          this.deal_stage_options.push({
            text: kbn_stg.stage_name,
            value: kbn_stg.id,
          });
        }

        this.new_deal_data.deal_stage = this.deal_stage_options[0].value;
      } catch (error) {
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    async get_customer_data() {
      try {
        this.customer_loading = true;

        const response = await API().get(
          `api/internal-admin/loan-inquiry/read-customers-by-email?email=${this.inquiry_data.email}`
        );

        console.log(response.data.message);

        this.found_customers = response.data.customers;

        if (this.found_customers.length > 0) this.toggle_choice = 0;

        this.initialize_new_customer_data();
        this.initialize_new_deal_data();
        this.initialize_deal_note();
        this.get_existing_contact_info();
        this.get_existing_kanban_stages();
        this.customer_loading = false;
      } catch (error) {
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },
  },

  computed: {
    filtered_found_customers() {
      return this.found_customers.map((customer) => {
        const email = customer.contact_infos.some(
          (contact_info) => contact_info.type === "Email"
        )
          ? customer.contact_infos.find(
              (contact_info) => contact_info.type === "Email"
            ).email
          : "";

        const cell_phone_number = customer.contact_infos.some(
          (contact_info) => contact_info.type === "Cell Phone Number"
        )
          ? customer.contact_infos.find(
              (contact_info) => contact_info.type === "Cell Phone Number"
            ).number
          : "";

        const home_phone_number = customer.contact_infos.some(
          (contact_info) => contact_info.type === "Home Phone Number"
        )
          ? customer.contact_infos.find(
              (contact_info) => contact_info.type === "Home Phone Number"
            ).number
          : "";

        const work_phone_number = customer.contact_infos.some(
          (contact_info) => contact_info.type === "Work Phone Number"
        )
          ? customer.contact_infos.find(
              (contact_info) => contact_info.type === "Work Phone Number"
            ).number
          : "";

        let address = "";

        if (customer.addresses.length > 0) {
          const [
            {
              street_number,
              street_name,
              street_type,
              street_direction,
              unit,
              city,
              province,
              postal_code,
            },
          ] = customer.addresses;

          address = `${
            street_number
              ? this.$options.filters.capitalize(String(street_number)) + ", "
              : ""
          } ${
            street_name
              ? this.$options.filters.capitalize_each_word(
                  String(street_name)
                ) + ", "
              : ""
          } ${street_type ? street_type + ", " : ""} ${
            street_direction ? street_direction + ", " : ""
          } ${
            unit ? this.$options.filters.capitalize(String(unit)) + ", " : ""
          } ${
            postal_code
              ? this.$options.filters.capitalize(String(postal_code)) + ", "
              : ""
          }${
            city
              ? this.$options.filters.capitalize_each_word(String(city)) + ", "
              : ""
          } ${
            province
              ? this.$options.filters.capitalize_each_word(String(province))
              : ""
          } `;
        }

        const deal_count = customer.int_custs.length;

        return {
          id: customer.id,
          customer_name: `${customer.salutation}. ${customer.first_name} ${customer.last_name}`,
          email: email,
          cell_phone_number: cell_phone_number,
          home_phone_number: home_phone_number,
          work_phone_number: work_phone_number,
          address: address,
          deal_count: deal_count,
        };
      });
    },
  },

  mounted() {
    this.get_customer_data();
  },

  filters: {
    ...filt,
  },
};
</script>

<style scoped>
.sticky-row {
  position: sticky;
  top: 64px;
  z-index: 2;
}

.steppers-card {
  background-color: #111;
  min-height: 635px;
}
</style>
